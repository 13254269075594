import React from "react"
import { graphql } from 'gatsby'

import SEO from "../components/seo"
import TopMenu from "../components/topmenu"
import Footer from "../components/footer"
import Services from "../components/services"
import Jobs from "../components/jobs"
// import Reference from "../components/reference"
import ContactUs from "../components/contact-us"
import AboutUs from "../components/aboutUs"
import CookiesPopup from "../components/cookies-popup"
import { ContactPopupFrame } from "../components/contact-popup"

const IndexPageTemplate = ({ data, pageContext }) => {
  const slug = data.page.fields.slug;
  const about = data.page.frontmatter.about;
  const jobs = data.page.frontmatter.jobs;
  const listJobs = data.jobs.nodes;
  const listContacts = data.contacts.nodes;
  // const listRefs = data.refs.nodes;
  const contacts = data.page.frontmatter.contacts;
  const settings = data.settings.childMdx.frontmatter;
  const services = data.page.frontmatter.services;
  // const references = data.page.frontmatter.references;
 

  // console.warn("pageContext", pageContext);

  //Popup control
  const expiredTime = 40 * 1000; //Show popup after 40sec

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'contact_popup_open',
    });
  }

  React.useEffect(() => {
    setTimeout(() => handleOpen(), expiredTime);
  }, []);

  return (
    <section className="bot-page color-white">
      <SEO
        description={data.page.frontmatter.description}
        keywords={[`Embedded`, `Sulautetut`, `sulautettu`, `Tickingbot`, `software`, `consultant`, `ohjelmistokonsultti`, `Test automation`, `testiautomaatio`, `testausautomaatio`]} />

      <TopMenu
        language={pageContext.language}
        languages={pageContext.languages}
        slug={slug}
        translation={pageContext.translation}
        services={services.services}
        jobs={jobs}
        listJobs={listJobs}
        settings={settings} />
      <div className="bot-page__in">
        <AboutUs info={about}></AboutUs>
        <Services info={services} contacts={listContacts} translation={pageContext.translation} settings={settings}></Services>
        <Jobs info={jobs} list={listJobs} translation={pageContext.translation}></Jobs>
        {/* <Reference info={references} list={listRefs}></Reference> */}
        <ContactUs info={contacts} list={listContacts} translation={pageContext.translation} settings={settings}></ContactUs>
      </div>
      <Footer settings={settings}></Footer>

      {
        // cookiesPopUpVisible && !cookiesPopupClosed() &&
        <CookiesPopup
          translation={pageContext.translation}>
        </CookiesPopup>}
      {
        //A hidden form for Netlify to detect the contactPopup form inside ContactPopupFrame
      }
      <form method="post" autoComplete="off" name="contactPopup" id="contactPopup" data-netlify="true" data-netlify-honeypot="bot-field" hidden>
        <input type="hidden" name="form-name" value="contactPopup" />
        <input type="text" name="fname"/>
        <input type="text" name="lname"/>
        <input type="tel" name="phone"/>
        <input type="email" name="email"/>
        <input type="text" name="subject"/>
        <input type="text" name="message"/>
      </form>
      {
        <ContactPopupFrame translation={pageContext.translation} open={open} onClose={handleClose}></ContactPopupFrame>
      }
    </section>
  )
}
export const indexQuery = graphql`
  query IndexById($id: String!, $language: String!) {
    page: mdx(
      id: { eq: $id }
    ) {
      fields {
        slug
      }
      frontmatter {
        language
        title
        description
        about {
          title
          subtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          description
        }
        services {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 2500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          subtitle
          description
          services {
            title
            description
            contact
            expert
          }
        }
        jobs {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 2500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          subtitle
          description
          positionTitle
          list{
            position
          }
        }
        references {
          cases {
            title
            description
          }
          description
          title
          image {
            childImageSharp {
              fluid(maxWidth: 2500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          subtitle
          list {
            reference
          }
        }
        contacts {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 2500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          subtitle
          description
          list {
            contact
          }
        }
      }
    }
    jobs: allMdx(filter: {frontmatter: {contentType: {eq: "job"}, language: {eq: $language}}}) {
      nodes {
        fields{
          slug
        }
        frontmatter {
          language
          title
          slug
          shortdescription
          enabled
        }
      }
    }
    refs: allMdx(filter: {frontmatter: {contentType: {eq: "reference"}}}) {
      nodes {
        fields{
          slug
        }
        frontmatter {
          name
          logo {
            publicURL
          }
          link
        }
      }
    }
    contacts: allMdx(filter: {frontmatter: {contentType: {eq: "contact"}, language: {eq: $language}}}) {
      nodes {
        fields{
          slug
        }
        frontmatter {
          name
          avatar{
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          phone
          email
          description
          linkedin
          enabled
        }
      }
    }
    settings: file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
      childMdx {
        frontmatter {
          companyname
          sitename
          address
          address2
          phone
          linkedin
          email
        }
      }
    }
  }
`
export default IndexPageTemplate
